<template>
  <el-dialog :visible="addDialogVisible"
             @open="open"
             :before-close="close">
    <div slot="title"
         class="left_top">
      <div class="left_icon">
        <span></span>
        <span>{{ title }}</span>
      </div>
    </div>
    <div class="dialog_top"
         v-if="title === '员工分配'">
      <span>身份证图:</span>
      <div class="flex_between">
        <div class="card-box">
          <img v-if="detail.idCardFront"
               :src="detail.idCardFront"
               alt />
          <img v-else
               src="https://file.lgbfss.com/www/zan_xu/card_font_bg.png"
               alt />
          <p style="margin: 0; font-size: 14px">身份证头像面</p>
        </div>
        <div class="card-box">
          <img v-if="detail.idCardBack"
               :src="detail.idCardBack"
               alt />
          <img v-else
               src="https://file.lgbfss.com/www/zan_xu/card_back_bg.png"
               alt />
          <p style="margin: 0; font-size: 14px">身份证国徽面</p>
        </div>
      </div>
    </div>
    <el-descriptions title="用户信息"
                     v-if="title === '员工分配'">
      <el-descriptions-item label="员工姓名">{{ detail.name }}</el-descriptions-item>
      <el-descriptions-item label="性别">{{ detail.sex }}</el-descriptions-item>
      <el-descriptions-item label="年龄">
        <span v-if="detail.idCardNumber">{{ year - parseInt(detail.idCardNumber.slice(6, 10)) }}</span>
      </el-descriptions-item>
      <el-descriptions-item label="手机号">{{ detail.phone }}</el-descriptions-item>
      <el-descriptions-item label="身份证号">{{ detail.idCardNumber }}</el-descriptions-item>
      <el-descriptions-item label="地址">{{ detail.address }}</el-descriptions-item>
      <el-descriptions-item label="注册时间">{{ detail.create_time }}</el-descriptions-item>
      <el-descriptions-item label="紧急联系人">{{ detail.sosUserName }}</el-descriptions-item>
      <el-descriptions-item label="联系方式">{{ detail.sosUserPhone }}</el-descriptions-item>
      <el-descriptions-item label="关系">{{ detail.sosRelation }}</el-descriptions-item>
      <el-descriptions-item label="联系地址">{{detail.address }}</el-descriptions-item>
    </el-descriptions>
    <el-form ref="ruleForm"
             :model="ruleForm"
             label-width="80px"
             :rules="rules">
      <el-row type="flex"
              class="row-bg"
              justify="space-between">
        <el-col :span="8">
          <el-form-item label="用工企业"
                        prop="workEnterpriseCode">
            <el-select remote
                       reserve-keyword
                       :remote-method="query"
                       @change="getCity"
                       v-model="ruleForm.workEnterpriseCode"
                       clearable
                       filterable
                       placeholder="请输入企业名称"
                       style="margin-right:10px;">
              <el-option v-for="(item,index) in eList"
                         :disabled="!item.enable"
                         :key="index"
                         :label="item.name"
                         :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="企业区域"
                        prop="workEnterpriseAreaCode">
            <el-select v-model="ruleForm.workEnterpriseAreaCode"
                       @change="querycity"
                       style="margin-right:10px;"
                       filterable
                       clearable
                       :placeholder="'请输入企业区域'">
              <el-option-group v-for="group in areaSelectList"
                               :key="group.provinceName"
                               :label="group.provinceName">
                <span style="color:red"
                      slot="label">{{ group.provinceName }}</span>
                <el-option v-for="(item,index) in group.citys"
                           :key="index"
                           :label="item.cityName"
                           :value="item.code"></el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="企业门店"
                        prop="workEnterpriseStoreCode">
            <el-select remote
                       reserve-keyword
                       v-model="ruleForm.workEnterpriseStoreCode"
                       clearable
                       filterable
                       placeholder="请输入企业门店"
                       style="margin-right: 10px;">
              <el-option v-for="(item,index) in storeList"
                         :key="index"
                         :label="item.name"
                         :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex"
              class="row-bg"
              justify="space-between">
        <el-col :span="8">
          <el-form-item label="工作性质"
                        prop="kind">
            <el-radio-group v-model="ruleForm.kind">
              <el-radio label="1">全职</el-radio>
              <el-radio label="2">灵活用工</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item>
        <el-button type="primary"
                   @click="onSubmit('ruleForm')">提交</el-button>
        <el-button @click="closeDialog('ruleForm')">取 消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { api } from '/src/api/base';
export const userDescribe = api()('user.describe.json');
export const staffAdd = api()('staff.add.json');

// 企业列表

export const workEnterpriseList = api()('work_enterprise.list.json');
// 获取企业区域列表
export const workEnterpriseAreaList = api()('work_enterprise_area.list.json');
// 获取企业区域门店
export const workEnterpriseStoreList = api()('work_enterprise_store.list.json');
// 企业名称获取企业区域列表
export const workEnterpriseAreaContainArea = api()('work_enterprise_area.containArea.json');
// 企业区域下模板列表
export const contractTemplateEnterpriseList = api('')(
  'contractTemplate.enterprise.list.json'
);

export default {

  data () {
    return {

      detail: {},
      year: new Date().getFullYear(),
      eList: [],
      storeList: [],
      areaSelectList: [],
      choice: false,
      templeteObject: null,
      name: '',
      type: '',
      ruleForm: {

        workEnterpriseCode: '',
        workEnterpriseAreaCode: '',
        workEnterpriseStoreCode: '',
        kind: '',
      },
      contractTemplate: '',
      rules: {
        workEnterpriseCode: [
          {
            required: true,
            message: '请选择企业',
            trigger: 'change',
          },
        ],
        workEnterpriseAreaCode: [
          {
            required: true,
            message: '请选择区域',
            trigger: 'change',
          },
        ],
        workEnterpriseStoreCode: [
          {
            required: true,
            message: '请选择门店',
            trigger: 'change',
          },
        ],
        kind: [
          {
            required: true,
            message: '请选择工作性质',
            trigger: 'change',
          },
        ],
      },


    };
  },
  props: {
    addDialogVisible: Boolean,
    title: String,
    code: String,
    codeList: Array
  },

  methods: {

    getTemplate () {
      contractTemplateEnterpriseList({
        workEnterpriseCode: this.ruleForm.workEnterpriseCode,
        workEnterpriseArea: this.ruleForm.workEnterpriseAreaCode,
      }).then(res => {
        console.log(res);
      }).catch(err => {
        console.log(err);
      })
    },

    getCity (val) {
      if (val) {
        const params = {
          workEnterpriseCode: val
        };
        workEnterpriseAreaContainArea(params).then(res => {
          this.areaSelectList = res
          this.ruleForm.workEnterpriseAreaCode = ''
          this.ruleForm.workEnterpriseStoreCode = ''
          this.storeList = []
        }).catch(err => {
          console.log(err);
        })
      }
      else {
        this.areaSelectList = []
        this.ruleForm.workEnterpriseAreaCode = ''
        this.ruleForm.workEnterpriseStoreCode = ''
        this.storeList = []
      }

    },
    close () {
      this.closeDialog('ruleForm')
    },
    choiceChange (val) {
      val.subjectAccount = ''
      val.subjectName = ''
    },

    querycity (val) {
      if (val) {
        workEnterpriseStoreList({
          workEnterpriseAreaCode: val,
          pageNumber: 1,
          pageSize: 1000
        }).then(res => {
          this.storeList = res.list
          this.ruleForm.workEnterpriseStoreCode = ''
          this.getTemplate()

        }).catch(err => {
          console.log(err);
        })
      }

      else {
        this.ruleForm.workEnterpriseStoreCode = ''
        this.storeList = []
      }
    },
    enterpriseList (val) {

      const params = {
        keywords: val,
        pageNumber: 1,
        pageSize: 1000
      };
      workEnterpriseList(params).then(res => {
        this.eList = res.list

      });
    },
    getInfo () {
      const params = {
        code: this.code,
      };
      userDescribe(params).then(res => {
        this.detail = res

      }).catch(err => {
        console.log(err);
      })
    },
    query (val) {

      this.pageNumber = 1;
      this.enterpriseList(val);
    },
    onSubmit (formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          var params = {}
          if (this.title === '员工分配') {
            params = {
              kind: this.ruleForm.kind,
              users: [{ code: this.code, name: this.detail.name }],
              workEnterpriseStoreCode: this.ruleForm.workEnterpriseStoreCode,
            };
          }
          else {
            params = {
              kind: this.ruleForm.kind,
              users: this.codeList,
              workEnterpriseStoreCode: this.ruleForm.workEnterpriseStoreCode,

            };
          }

          staffAdd(params).then((res) => {
            if (res.success) {
              this.closeDialog(formName)
            }
            else {
              this.$message({
                message: this.title === '批量分配' ? '选择人员中有人已经被分配' : '该人员被其他人分配',
                type: 'warning'
              })
              this.closeDialog(formName)
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    open () {
      this.enterpriseList()
      if (this.title === '员工分配') {
        this.getInfo()
      }
    },

    closeDialog (val) {
      this.resetForm(val)
      this.$emit('update:addDialogVisible', false);
      this.$emit('func', this.addDialogVisible);
    }
  }
};
</script>
<style>
.el-form-item__label {
  display: flex !important;
  padding-bottom: 0 !important;
}
</style>
<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.el-form-item {
  text-align: left;
  margin: 0;
  width: 100%;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .card-box {
    margin: 10px;
    padding: 1px;
    text-align: center;
    color: grey;
    width: 50%;

    img {
      width: 100%;
      height: 100px;
    }
  }
}

.dialog_top {
  display: flex;
  align-items: center;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
